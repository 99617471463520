/* eslint-disable import/no-cycle */
import * as React from 'react'
import StoryblokService from 'utils/storyblok-service'
import { media } from 'utils/transforms'
import FullScreenContentComponent from './FullScreenContent'

export const transformProps = (propsArg) => {
  const props = { ...propsArg }

  if (props.backgroundMedia && props.backgroundMedia.filename) {
    props.backgroundMediaProps = media(props.backgroundMedia)

    delete props.backgroundMedia
  }

  if (props.backgroundMediaMobile && props.backgroundMediaMobile.filename) {
    props.backgroundMediaMobile = media(props.backgroundMediaMobile)
  }

  if (props.content) {
    props.content = StoryblokService?.client?.richTextResolver?.render(props.content)
  }

  return props
}
const FullScreenContent = (props) => <FullScreenContentComponent {...transformProps(props)} />

export default FullScreenContent
