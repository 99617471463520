import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Media from '@oakwood/oui/Media'
import MediaReveal from '@oakwood/oui/MediaReveal'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { mediaType } from 'utils'
import RouterLink from 'containers/RouterLink'
import Button from 'components/Button'
import Container from 'components/Container'
import Html from 'components/Html'
import Section from 'components/Section'

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: 'calc(100vh - 50px)',
    color: theme.palette.common.black,
  },
  textColorInverted: {
    color: theme.palette.common.white,
  },
  backgroundMediaContainer: {
    ...theme.mixins.absolute(0),
    zIndex: -150,
  },
  backgroundMedia: {
    height: '100%',
  },
  main: {
    ...theme.mixins.verticalRhythm(3),
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
  },
  cta: {
    maxWidth: 390,
  },
  ctaWrap: {
    display: 'flex',
    width: '100%',
  },
  contentLeft: {
    marginLeft: '0',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  contentRight: {
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
  contentCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))
const BREAKPOINT_KEY_UP = 'sm'

function FullScreenContent(props) {
  const {
    backgroundMediaProps,
    backgroundMediaMobile,
    content,
    ctaLabel,
    ctaUrl,
    txtColor = 'default',
    ctaType = 'contained',
    ctaButtonSize = 'medium',
    txtAlign = 'center',
    ctaAlign = 'center',
    contentSize = 'medium',
    contentPadding = '',
    contentAlign = 'center',
  } = props
  const classes = useStyles(props)

  let cType = 'contained'
  if (ctaType.includes('outlined')) {
    cType = 'outlined'
  } else if (ctaType.includes('contained')) {
    cType = 'contained'
  } else {
    cType = ctaType
  }

  const isBreakpointUp = useMediaQuery((theme) => theme.breakpoints.up(BREAKPOINT_KEY_UP))

  let mobileMedia

  if (backgroundMediaMobile?.breakpoints) {
    mobileMedia = true
  } else if (backgroundMediaMobile?.src) {
    mobileMedia = true
  } else {
    mobileMedia = false
  }

  return (
    <>
      <Section
        className={clsx(classes.root, {
          [classes.textColorInverted]: txtColor === 'inverted',
        })}
      >
        <MediaReveal className={classes.backgroundMediaContainer}>
          {(isBreakpointUp && backgroundMediaProps) || !mobileMedia ? (
            <Media className={classes.backgroundMedia} {...backgroundMediaProps} />
          ) : (
            <Media className={classes.backgroundMedia} {...backgroundMediaMobile} />
          )}
        </MediaReveal>
        <Container
          className={clsx(classes.main, {
            [classes.contentLeft]: contentAlign === 'left',
            [classes.contentCenter]: contentAlign === 'center',
            [classes.contentRight]: contentAlign === 'right',
          })}
          style={{
            padding: contentPadding,
          }}
          maxWidth="sm"
        >
          {content && (
            <Html
              style={{ textAlign: txtAlign, fontSize: contentSize || 'medium' }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}

          {ctaLabel && ctaUrl && (
            <div className={classes.ctaWrap} style={{ justifyContent: ctaAlign }}>
              <Button
                className={classes.cta}
                component={RouterLink}
                href={ctaUrl}
                variant={cType}
                size={ctaButtonSize}
              >
                {ctaLabel}
              </Button>
            </div>
          )}
        </Container>
      </Section>
    </>
  )
}

FullScreenContent.propTypes = {
  backgroundMediaProps: mediaType,
  backgroundMediaMobile: mediaType,
  content: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaType: PropTypes.string,
  ctaButtonSize: PropTypes.string,
  txtColor: PropTypes.oneOf(['default', 'inverted']),
  txtAlign: PropTypes.oneOf(['left', 'center', 'right', 'justify', '']),
  ctaAlign: PropTypes.oneOf(['left', 'center', 'right', '']),
  contentSize: PropTypes.oneOf(['small', 'medium', 'large', 'x-large', '']),
  contentPadding: PropTypes.string,
  contentAlign: PropTypes.string,
}

export default FullScreenContent
